import React from "react";
import {
  SortingState,
  FilteringState,
  IntegratedSorting,
  PagingState,
  CustomPaging,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  TableFilterRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-material-ui";

import CircularProgress from "@material-ui/core/CircularProgress";
import MaterialButton from "@material-ui/core/Button/Button";
import Button from "../../components/CustomButtons/Button";
import swal from "sweetalert";
import Close from "@material-ui/icons/Close";

// const FilterIcon = ({ type, ...restProps }) => {
//   if (type === "month") return <DateRange {...restProps} />;
//   return <TableFilterRow.Icon type={type} {...restProps} />;
// };

const URL =
  process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] +
  "/api/tests/results";
const URL_BASE =
  process.env["REACT_APP_HOST_" + process.env.NODE_ENV.toUpperCase()] + "/api";

const columns = [
  {
    title: "Итого",
    name: "score",
    withFiltering: true,
  },
  {
    title: "Статус",
    name: "passedText",
    withFiltering: true,
  },
  {
    title: "ФИО",
    name: "name",
    width: 300,
    withFiltering: true,
  },
  {
    title: "Должность",
    name: "professionName",
    withFiltering: true,
  },
  {
    title: "Департамент",
    name: "departmentName",
    withFiltering: true,
  },
  {
    title: "Дата",
    name: "dateText",
    withFiltering: true,
  },
  {
    title: "Начало теста",
    name: "startDateText",
    withFiltering: false,
  },
  {
    title: "Конец теста",
    name: "endDateText",
    withFiltering: false,
  },
  {
    title: "Продолжительность",
    name: "takenTime",
    withFiltering: false,
  },
  {
    title: "Правильных ответов",
    name: "rightAnswersTotal",
    withFiltering: false,
  },
  {
    title: "Общие вопросы",
    name: "general",
    withFiltering: false,
  },
  {
    title: "Профессиональные вопросы",
    name: "professional",
    withFiltering: false,
  },
  {
    title: "Неотвеченные вопросы",
    name: "notAnsweredQuestions",
    withFiltering: false,
  },
  {
    title: "IP адрес",
    name: "ipAddress",
    withFiltering: true,
  },

  {
    title: "Удалить",
    name: "actions",
    withFiltering: false,
  },
];

class CustomFilterCell extends React.PureComponent {
  render() {
    const { column, ...restProps } = this.props;
    if (!column.withFiltering) {
      return <TableFilterRow.Cell column={column} />;
    }

    return <TableFilterRow.Cell column={column} {...restProps} />;
  }
}

const TableHeaderContentBase = ({ column, children, ...restProps }) => (
  <TableHeaderRow.Content
    column={column}
    style={{
      textAlign: "center",
    }}
    {...restProps}
  >
    {children}
  </TableHeaderRow.Content>
);

const tableColumnExtensions = columns.map((column) => {
  let newColumn = {};

  if (column.name === "title") {
    newColumn.width = 200;
  }
  newColumn.align = "center";
  newColumn.columnName = column.name;
  newColumn.wordWrapEnabled = true;

  return newColumn;
});

class ExtendedTable extends React.Component {
  state = {
    columns,
    data: [],
    rows: [],
    totalCount: 0,
    pageSize: 6,
    currentPage: 0,
    loading: true,
    pageSizes: [5, 10, 15, 0],
    tableColumnExtensions,
  };

  timeOut = null;
  filters = "";

  componentDidMount() {
    this.loadData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // this.loadData()
  }

  changeCurrentPage = async (currentPage) => {
    await this.setState({
      loading: true,
      currentPage,
    });
    this.loadData();
  };

  queryString = () => {
    const { pageSize, currentPage } = this.state;
    return `${URL}/${pageSize}/${pageSize * currentPage}`;
  };

  changeFilters = (filters) => {
    // Listen for keystroke events

    // Clear the timeout if it has already been set.
    // This will prevent the previous task from executing
    // if it has been less than <MILLISECONDS>
    clearTimeout(this.timeOut);

    // Make a new timeout set to go off in 800ms
    this.timeOut = setTimeout(() => {
      this.setState({
        loading: true,
        filters,
      });

      console.log(this.filterQueryString(filters));
    }, 100);
  };

  filterQueryString(filters) {
    let filter = filters.reduce((acc, { columnName, value }) => {
      acc.push([columnName, value]);
      return acc;
    }, []);

    const headers = new Headers();
    headers.append(
      "Authorization",
      `Bearer ${localStorage.getItem("jwt_token")}`
    );
    headers.append("Content-Type", "application/json");

    fetch(`${URL_BASE}/tests/filter`, {
      method: "POST",
      headers,
      body: JSON.stringify({
        filter,
      }),
    })
      .then((resJSON) => resJSON.json())
      .then((res) => {
        if (res.data && res.data.length > 0) {
          this.setState({
            pageSize: 1,
            totalCount: 1,
            loading: false,
          });
        } else {
          this.setState({
            loading: false,
          });
        }
        this.setData(res.data);
      })
      .catch((e) => {
        console.log(e);
        this.loadData();
      });
  }

  setData = (data) => {
    this.setState({
      loading: false,
      data: data.map((result, key) => {
        return {
          key: key,
          name: (
            <MaterialButton
              style={{ width: "100%", whiteSpace: "pre-line" }}
              onClick={() => this.getAnswerHistory(result.name, result.answers)}
              color={"primary"}
            >
              {result.name}
            </MaterialButton>
          ),
          passedText: (
            <b
              style={{
                width: "100%",
                display: "block",
                textAlign: "center",
                color: result.score >= result.passScore ? "green" : "tomato",
              }}
            >
              {" "}
              {result.passedText}
            </b>
          ),
          professionName: result.profession.name,
          departmentName: result.department.name,
          dateText: result.dateText,
          startDateText: result.startDateText,
          endDateText: result.endDateText,
          takenTime: result.takenTime,
          professional: result.rightAnswers.professional,
          general: result.rightAnswers.general,
          notAnsweredQuestions: result.notAnsweredQuestions,
          ipAddress: result.ipAddress,
          rightAnswersTotal: result.rightAnswersTotal,
          score: (
            <b
              style={{
                width: "100%",
                display: "block",
                textAlign: "center",
                color: result.score >= 65 ? "green" : "tomato",
              }}
            >
              {result.score}%
            </b>
          ),
          actions: (
            // we've added some custom button actions
            <div className="actions-right">
              {/* use this button to add a like kind of action */}
              {/* use this button to add a edit kind of action */}
              {/* use this button to remove the data row */}
              <Button
                justIcon
                round
                simple
                onClick={async () => {
                  swal({
                    title: "Вы уверены?",
                    text: "После удаления, восстановление невозможно",
                    icon: "warning",
                    buttons: ["Отмена", "Удалить!"],

                    dangerMode: true,
                  }).then(async (willDelete) => {
                    if (willDelete) {
                      let data = this.state.data;

                      let err = true;
                      // let [err, done] = await to(post(process.env['REACT_APP_HOST_' + process.env.NODE_ENV.toUpperCase()] + '/api/tests/results/remove', {
                      //   id: result['_id']
                      // }))

                      if (!err) {
                        data.find((o, i) => {
                          if (o.key === key) {
                            // here you should add some custom code so you can delete the data
                            // from this component and from your server as well
                            data.splice(i, 1);
                            return true;
                          }
                          return false;
                        });
                        this.setState({ data: data });

                        swal("Результат успешно удален!", {
                          icon: "success",
                        });
                      } else {
                        swal(
                          "Ошибка!",
                          "Произошла ошибка при удалении!",
                          "error"
                        );
                      }
                    } else {
                      swal("Отменилось!");
                    }
                  });
                }}
                color="danger"
                className="remove"
              >
                <Close />
              </Button>{" "}
            </div>
          ),
        };
      }),
    });
  };
  loadData = () => {
    const queryString = this.queryString();
    // if (queryString === this.lastQuery) {
    //   if (this.state.loading) {
    //     this.setState({ loading: false });
    //   }
    //   return;
    // }

    const headers = new Headers();
    headers.append(
      "Authorization",
      `Bearer ${localStorage.getItem("jwt_token")}`
    );

    fetch(queryString, {
      method: "GET",
      headers,
    })
      .then((response) => response.json())
      .then((response) => {
        this.setData(response.results);
        this.setState({
          totalCount: response.count,
          loading: false,
        });
      })
      .catch((e) => {
        window.location.href = "/pages/login-page";
        console.log(e);
      });

    // this.lastQuery = queryString;
  };

  render() {
    const {
      data,
      columns,
      pageSize,
      currentPage,
      totalCount,
      loading,
    } = this.state;

    return (
      <React.Fragment>
        <React.Fragment>
          <Grid rows={data} columns={columns}>
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={this.changeCurrentPage}
              pageSize={pageSize}
            />
            <CustomPaging totalCount={totalCount} />

            <FilteringState onFiltersChange={this.changeFilters} />

            <SortingState
              mode="multiple"
              defaultSorting={[{ columnName: "product", direction: "asc" }]}
            />

            <IntegratedSorting mode="multiple" material />

            <Table columnExtensions={this.state.tableColumnExtensions} />
            <TableHeaderRow
              showSortingControls
              contentComponent={TableHeaderContentBase}
            />
            <PagingPanel pageSize={this.state.pageSizes} />
            <TableFilterRow
              messages={{
                filterPlaceholder: "Фильтр",
                contains: "Содержит",
              }}
              cellComponent={CustomFilterCell}
            />
          </Grid>
          {loading && (
            <div
              style={{
                position: "absolute",
                top: "0",
                left: "0",
                width: "100%",
                height: "100%",
                background: "rgba(255, 255, 255, .3)",
              }}
            >
              <CircularProgress
                style={{
                  position: "absolute",
                  fontSize: "20px",
                  top: "calc(45% - 10px)",
                  left: "calc(50% - 10px)",
                }}
              />
            </div>
          )}{" "}
        </React.Fragment>
      </React.Fragment>
    );
  }
}

export default ExtendedTable;
